<template>
  <div class="container pt-5 mt-5 mt-sm-0 position-relative"
    id="how-it-works" ref="how"
  >
      <p  v-html="texts.intro" v-fade-in="{delay:120}"></p>

      <h4 class="text-left" v-fade-in="{delay:210}"> Life Events and Life Stages </h4>
      <p v-fade-in="{delay:300}">{{texts.life}}</p>
      <!-- <img src="/images/how-it-works/life-events.svg" class="mb-4" alt=""> -->

      <div id="life-events" class="py-4 my-4 w-100" v-fade-above="{delay:400}">
         <div class="" >
                <div class="position-relative" style="left:70%;width:20%">
                  <div class="come-down">
                    <a href="#ap" class="bg-white px-2">
                      Annuity Plan
                    </a>
                  </div>
                  <div class="range-bracket" style="height:50px">
                    
                  </div>
                </div>
          </div>
          <div class="" >
                <div class="position-relative " style="left:30%;width:40%">
                  <div class="come-down">
                       <a href="#term" class="px-2 bg-white">
                        Term Life Insurance Plan
                      </a>
                   </div>
                  <div class="range-bracket" style="height:50px">
                      
                  </div>
                </div>
          </div>
      
          <div class=" d-flex flex-column w-100 position-relative "
           style="height:6em;background-color:#57acc7">
            
            <div class="my-auto">
              <!-- <hr style="border-top: 1px dashed white;" /> -->
              <div class="super-dash"/>
            </div>
            

            <!-- <div v-for="(e,n) in levents" :key="e.name+n"
            class="position-absolute event-pill" :style="{'left':e.left+'%'}">
              {{e.name}}
            </div> -->
            <div v-for="(e,n) in levents" :key="e.name+n+50"
            class="position-absolute h-100 d-flex" :style="{'left':e.left+'%'}">

                <div class="event-pill my-auto">
                    {{e.name}}
                </div>

                <div class="number-circle position-absolute d-flex" 
                :class="((n==1||n==2)?'top-up':'bottom-up')">
                  <p class=" p-0 m-auto">
                  {{'0'+(n+1)}}
                  </p>
                </div>
                
            </div>


          </div>
           <div class="" >
                <div class="position-relative " style="left:10%;width:80%">
                  <div class="range-bracket-inverse" style="height:90px">
                      
                  </div>
                  <div class="come-up">
                    <a class="bg-white px-2" href="#ci">
                      Critical Illness And Medical Insurance Plans
                    </a>
                    </div>
                </div>
          </div>

      </div>


      <h4 class="text-left mb-4" v-fade-in="{delay:100}"> The Mechanism </h4>
      <p  v-html="texts.mechanism" v-fade-in="{delay:300}"></p>
      
      <h4 class="text-center mb-4"> Closer look at the Recommender </h4>
      <img id="iceberg" class="mb-4" v-fade-above="{delay:200,offset:0.8}"
      src="/images/how-it-works/iceberg.svg" alt="">

      <h4  id="term" class="text-left mb-4" v-fade-in="{delay:100}"> Term Life Insurance plan </h4>
      <p v-html="texts.termPlan" v-fade-in="{delay:200}"></p>
              

      <h5 class="text-center text-dark mb-4"> Scenario 1 - Insufficient Household Income </h5>

      <div class="d-flex justify-content-center">
      <div >
          <p class="font-weight-bold">Recommendation:</p>
          <ul class="text-left ">
            <li>Buy <span class="punder-line">Term Life Insurance plan</span> until retirement</li>
            <li>Buy coverage for <span class="punder-line">post retirement</span> </li>
          </ul>
      </div>
      </div>
     
      <div class="row mb-4">
        <div class="col-md-6 d-flex flex-column">
          <img src="/images/how-it-works/scenario-1-1.svg" alt="" 
           v-fade-left="{delay:200}" class="mt-auto mb-3">
        </div>
        <div class="col-md-6 d-flex flex-column">
          <img src="/images/how-it-works/scenario-1-2.svg"
           v-fade-right="{delay:400}"  alt="" class="mt-auto">
        </div>
      </div>

      <h5 class="text-center text-dark mb-4"> Scenario 2 - Sufficient Household Income </h5>

      <div class="d-flex justify-content-center">
      <div >
          <p class="font-weight-bold">Recommendation:</p>
          <ul class="text-left ">
            <li>No <span class="punder-line ">Term Life Insurance</span> plan required (to protect until retirement)</li>
            <li>Buy coverage for <span class=" punder-line">post retirement</span> </li>
          </ul>
      </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-6 d-flex flex-column">
          <img src="/images/how-it-works/scenario-2-1.svg"
           v-fade-left="{delay:200}" alt="" class="mt-auto mb-2">
        </div>
        <div class="col-md-6">
          <img src="/images/how-it-works/scenario-2-2.svg"
           v-fade-right="{delay:400}" alt="">
        </div>
      </div>

        <h4 id="ci" class="text-left mb-4" v-fade-in="{delay:100}">
           Critical Illness and Medical Insurance plans 
        </h4>
      <p  v-html="texts.medical1" v-fade-in="{delay:200}"></p>
      <div class="row mb-4">
        <div class="col-md-6 d-flex flex-column">
          <h5 class="text-center text-dark mb-4">
            Scenario 1: Critical Illness Insurance plan – Earning Individual 
          </h5>
          <img src="/images/how-it-works/scenarioComp-1.svg"
           v-fade-left="{delay:200}" alt="" class="mt-auto mb-2">
        </div>
        <div class="col-md-6">
          <h5 class="text-center text-dark mb-4">
          Scenario 2: Critical Illness Insurance plan – Retired Individual 
          </h5>
          <img src="/images/how-it-works/scenarioComp-2.svg"
           v-fade-right="{delay:400}" alt="">
        </div>
      </div>
      <p  v-html="texts.medical2" v-fade-in="{delay:100}"></p>

      <h4 id="ap" class="text-left mb-4" v-fade-in="{delay:200}"> Annuity plans</h4>
      <p  v-html="texts.annuity" v-fade-in="{delay:300}"></p>
      <div>
        <h5 class="text-center text-dark mt-4">
          Corpus required After Retirement of an Earning Member
        </h5>
      <img id="iceberg" class="mb-4"  v-fade-above="{delay:200}"
      src="/images/how-it-works/household.svg" alt="">
      </div>

      <div class="scroll-to-top ml-3 position-fixed"
      style="right:24px;bottom:16px"
      v-show="scrolly>20"
       @click="scrollToTop">
       <span class="d-none d-sm-inline">
        Scroll to Top 
       </span>
        <i class="fas fa-arrow-up"></i>
      </div>
 
      <!-- <Footer /> -->
  </div>
</template>

<script type='text/javascript'> (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })(); </script>

<script>
import texts from '../texts'
import Footer from '../components/special/Footer'
export default {
  components:{Footer},
  props:{scrolly:{}},
  mounted() {
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1));
        if (el) {
          el.scrollIntoView();
        }
      }
  },
  data(){
    return{
      texts: texts.HowItWorks,
      levents:[
        {name:'Birth',left:10},
        {name:'First Job',left:30},
        {name:'Retirement',left:70},
        {name:'Death',left:90},

      ]
      
    }
  },
  methods:{
    scrollToTop(){
      this.$emit('scrollTop')
    }
  }
}
</script>

<style scoped>
#how-it-works > p{
  text-align: left;
  color: #595d72;
}
img{
  width: 100%;
 
}

#how-it-works{
  --dblue: #1f2258;
}
 

.punder-line{
  text-decoration: underline;
  color: var(--darkblue);
}
li{
  color: var(--blue);
}
h4{
  color: var(--darkerblue);
  font-weight: bold;
}

@media screen and (min-width:768px){
  #iceberg{
  height: 500px;
  width: unset;
 }
}

.scroll-to-top{
  position:absolute;
  bottom:8px;
  right:8px;
  padding: 8px 16px;
  border-radius: 3em;
  background-color: var(--blue);
  color: white;
  cursor: pointer;
  box-shadow: 0 0 8px lightblue;
}

.divider-lines{
  border: 2px solid white;
  border-style: none none dashed none;
}
.event-pill{
  background-color: var(--dblue);
  color: white;
  border-radius: 24px;
  padding: 0.08em 1.0em;
  transform: translateX(-50%);
}

.number-circle{
  background-color: var(--dblue);
  width: 2.4em;
  height: 2.4em;
  color:white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.5em;
  transform: translateX(-50%);

} 
.number-circle.bottom-up{
  bottom:110%;
}
.number-circle.top-up{
  top:110%;
}

.range-bracket{
  border-top-right-radius: 12px;
  border-top-left-radius: 12px ;
  border: 1px solid var(--bluegray);
  border-bottom-width: 0;
  margin-bottom: 4px;
}
.range-bracket-inverse{
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px ;
  border: 1px solid var(--bluegray);
  border-top-width: 0;
  margin-top: 4px;
}
.come-up{
  transform: translateY(-50%);
}
.come-down{
  transform: translateY(50%);
}
#life-events{
  font-size: 0.9em;
}
.come-up,.come-down{
  line-height: 0.99em;
  text-align: center;
  word-wrap: break-word;
}
@media screen and (max-width:540px) {
    #life-events{
      font-size: 0.7em;
    }
    .event-pill{
      font-size: 0.8em;
    }
}

a{
  color: slategray;
  text-decoration: underline;
  font-size: 1.2em;
}
a:hover{
  color: var(--blue);
}

.super-dash{
   width: 100%;
   height: 4px;
  background-image: linear-gradient(to right, white 50%,transparent 50%);
  background-position: top;
  background-size: 2em 0.16em;
  background-repeat: repeat-x;
}
</style>